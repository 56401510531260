<template>
  <div>
    <page-component
      pageTitle="Modifier Lists"
      :restaurantFilterAvailable="true"
      :headers="headers"
      :items="filteredModifierLists"
      :buttons="
        userCanEdit
          ? [
              {
                text: '+ Add New Modifier List',
                to: { params: { form: 'AddEditForm' } },
              },
            ]
          : []
      "
    >
      <template v-slot:[`item.itemIds`]="{ item }">
        {{ getModifierItemsNames(item.id) }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
        <span v-if="item.variation">Variation</span>
      </template>
      <template v-slot:[`item.restaurantId`]="{ item }">
        {{ getRestaurantName(item.restaurantId) }}
      </template>
      <template v-slot:[`item.range`]="{ item }">
        {{ item.min === -1 ? 0 : item.min }}{{ item.max === -1 ? `+` : "" }}
        {{ item.max === -1 ? "" : " - " + item.max }}
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="edit"
          @click="editItem(item)"
        />
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="delete"
          item-type="MODIFIER_LIST"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form
      v-if="userCanEdit"
      @close="$router.push({ params: { form: null, id: null } })"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapGetters } from "vuex"
import PageComponent from "../../../shared/PageComponent.vue"

export default {
  name: "modifier-lists",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Range", value: "range" },
        { text: "Modifier Items", value: "itemIds" },
        { text: "Linked Menu Items", value: "linkedMenuItem" },
        { text: "Restaurant", value: "restaurantId" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      editMode: false,
      searchTerm: "",
      modifierToEdit: {},
      restaurantID: null,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantSyncStatus", "getRestaurantName", "userCanEdit"]),
    ...mapState(["modifierLists", "restaurants", "modifierItems", "menuItems"]),
    filteredModifierLists() {
      return this.modifierLists.map(item => {
        return {
          ...item,
          linkedMenuItem: this.getLinkedMenuItemNames(item),
        }
      })
    },
  },
  methods: {
    editEnabled(restaurantId) {
      return !this.getRestaurantSyncStatus(restaurantId)
    },
    async editItem(item) {
      await this.$store.commit("setItemToEdit", item)
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
    getModifierItemsNames(modifierListId) {
      var modItems = this.modifierItems.filter(
        item => item.modifierListId == modifierListId
      )
      var modNames = []
      modItems.forEach(item => {
        modNames.push(item.name)
      })

      return modNames.length > 4
        ? modNames.slice(0, 3).join(", ") + " and more ..."
        : modNames.join(", ")
    },
    getLinkedMenuItemNames(modifierListId) {
      const linkedMenuItems = this.menuItems.filter(menuItem =>
        menuItem.modifierLists.some(list => list.modifierListId == modifierListId.id)
      )
      return linkedMenuItems.length > 0
        ? linkedMenuItems.map(item => item.name).join(", ")
        : "None"
    },
  },
}
</script>
<style lang="scss">
.table-card,
.header-style {
  background-color: var(--v-primary-base);
}
</style>
