<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Modifier Group"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="$emit('close')"
      @submit="submitHandle()"
    >
      <v-row justify="start" class="mt-2">
        <v-col cols="3">
          <v-text-field v-model="newMod.name" label="Modifier Group Name" outlined />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model.number="newMod.min"
            label="Minimum selection"
            outlined
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model.number="newMod.max"
            label="Maximum selection"
            outlined
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="newMod.selectionType"
            label="Selection Type"
            :items="selectionOptions"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="newMod.restaurantId"
            label="Restaurant"
            :items="restaurants"
            item-text="name"
            item-value="id"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12">
          <v-data-table
            :headers="modItemsHeaders"
            :items="linkedModItems"
            sort-by="ordinal"
          >
            <template slot="body.append">
              <tr v-show="linkModItemActive">
                <td colspan="4">
                  <v-autocomplete
                    ref="input"
                    v-model="itemToLink"
                    class="ma-1"
                    :items="modifierItems"
                    item-text="name"
                    return-object
                    outlined
                    clearable
                    @input="linkModItem()"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td>
                  <v-btn icon @click="linkModItemActive = true">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon @click="unlinkModItem(item)">
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../../shared/dialogs/FormDialog.vue"
import { mapState, mapActions } from "vuex"
import retrieveSquareItem from "../../../../mixins/retrieveSquareItem"
import pushSquareitem from "../../../../mixins/pushSquareItem"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  mixins: [retrieveSquareItem, pushSquareitem],
  data() {
    return {
      squareItem: {},
      closeDisabled: false,
      awaitingResponse: false,
      newMod: {
        name: "",
        min: "",
        max: "",
        selectionType: "",
        restaurantId: "",
      },
      selectionOptions: ["SINGLE", "MULTIPLE"],
      modItemsHeaders: [
        { text: "", value: "ordinal", width: 100 },
        { text: "Name", value: "name" },
        { text: "Price", value: "priceMoney" },
        { text: "Action", value: "action", width: 100 },
      ],
      linkModItemActive: false,
      itemToLink: null,
      mainErrorMessage: "",
      linkedModItems: [],
      newModItems: [],
    }
  },
  computed: {
    ...mapState([
      "restaurants",
      "firebaseRefs",
      "modifierLists",
      "modifierItems",
      "itemToEdit",
    ]),
    editMode() {
      return this.itemToEdit != null
    },
  },
  watch: {
    "$route.params.form": {
      async handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepareModItems()
            this.prepEdit()
            if (this.itemToEdit.restaurantId) {
              this.squareItem = await this.retrieveCatalogItemForUpdate({
                restaurantId: this.itemToEdit.restaurantId,
                itemId: this.itemToEdit?.id,
              })
            }
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.restaurants.length === 1) {
      this.newMod.restaurantId = this.restaurants[0].id
    }
  },
  methods: {
    ...mapActions(["updateModifierItem"]),
    async submitHandle() {
      //if (!this.$v.$invalid) {
      this.closeDisabled = this.awaitingResponse = true

      await this.$store
        .dispatch("updateModifierList", {
          modList: this.newMod,
          modItems: this.newModItems,
        })
        .then(() => {
          this.editMode ? this.updateSquareData(this.squareItem) : ""
          this.$emit("close")
          this.clearFields()
        })
        .catch(error => {
          this.mainErrorMessage = error.message
        })
    },
    prepEdit() {
      Object.assign(this.newMod, this.itemToEdit)
    },
    unlinkModItem(mod) {
      const linkedModIndex = this.linkedModItems.findIndex(item => item.id == mod.id)

      const isNewModItem = this.newModItems.some(item => item.id == mod.id)
      if (linkedModIndex > -1) {
        this.linkedModItems.splice(linkedModIndex, 1) // removes mod item from linkedModItems to remove from UI

        if (isNewModItem) {
          this.newModItems = this.newModItems.filter(item => item.id != mod.id) // filters out if mod item is new
        } else {
          this.newModItems.push({
            // removes modifierListId to update firebase
            id: mod.id,
            modifierListId: "",
          })
        }
      }
    },
    linkModItem() {
      if (this.itemToLink) {
        // if user is selecting a mod item from v-autocomplete
        this.linkedModItems.push({
          id: this.itemToLink.id,
          name: this.itemToLink.name,
          ordinal: this.itemToLink.ordinal,
          priceMoney:
            "$" +
            (parseFloat(this.itemToLink.priceMoney.amount || 0) / 100).toFixed(2),
        })
        this.newModItems.push({
          // for updating modifier items with a modifierListId
          id: this.itemToLink.id,
          modifierListId: this.itemToEdit?.id,
        })
      }

      this.linkModItemActive = false
      this.$nextTick(() => {
        this.itemToLink = null
      })
    },
    async updateSquareData(squareObj) {
      var objectToPush = Object.assign({}, squareObj)
      objectToPush.restaurantId = this.itemToEdit.restaurantId
      objectToPush.object.modifierListData.name = this.newMod.name
      objectToPush.object.modifierListData.selectionType = this.newMod.selectionType

      // check to see if mods were removed
      var modList = objectToPush.object.modifierListData.modifiers
      var modAmount = modList.length
      while (modAmount--) {
        if (
          this.linkedModItems.find(item => item.id == modList[modAmount].id) ==
          undefined
        ) {
          modList.splice(modAmount, 1)
        }
      }
      objectToPush.object.modifierListData.modifiers = modList
      // check if mods were added
      if (
        this.linkedModItems.length >
        objectToPush.object.modifierListData.modifiers.length
      ) {
        for (let i = 0; i < this.linkedModItems.length; i++) {
          if (
            typeof objectToPush.object.modifierListData.modifiers.find(
              mod => mod.id == this.linkedModItems[i].id
            ) == "undefined"
          ) {
            var modItem = this.linkedModItems[i]

            objectToPush.object.modifierListData.modifiers.push(
              this.formatModForSquare(modItem, i)
            )
          }
        }
      }
      this.pushDataToSquare(objectToPush)
    },
    formatModForSquare(modToCreate, index) {
      return {
        id: "#" + modToCreate.id,
        isDeleted: false,
        presentAtAllLocations: true,
        type: "MODIFIER",
        updatedAt: new Date().toISOString(),
        modifierData: {
          modifierListId: this.squareItem.object.id,
          name: modToCreate.name,
          ordinal: index,
          priceMoney: {
            amount: modToCreate.priceMoney.amount,
            currency: "CAD",
          },
        },
      }
    },
    clearFields() {
      this.newMod = { itemIds: [] }
      this.linkedModItems = []
      this.newModItems = []
      this.awaitingResponse = this.closeDisabled = false
      this.mainErrorMessage = ""
      this.$emit("close")
    },
    prepareModItems() {
      this.linkedModItems = this.modifierItems // loads matching modifierItems from settings if any
        .filter(item => item.modifierListId === this.$route.params.id)
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            ordinal: item.ordinal,
            priceMoney:
              "$" + (parseFloat(item.priceMoney.amount || 0) / 100).toFixed(2),
          }
        })
    },
  },
}
</script>

<style></style>
