import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["channels"]),
  },
  methods: {
    getAccessToken(itemRestaurantId) {
      return this.channels.find(
        channel =>
          channel.restaurantData &&
          channel.restaurantData[0]?.restaurantId == itemRestaurantId
      )?.accessToken
    },
  },
}
