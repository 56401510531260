import { functions } from "../firebaseConfig"
import { httpsCallable } from "firebase/functions"
import getSquareAccessToken from "./getSquareAccessToken"

export default {
  mixins: [getSquareAccessToken],
  methods: {
    async pushDataToSquare(dataObj) {
      const requestObj = {
        accessToken: this.getAccessToken(dataObj.restaurantId),
        object: dataObj,
      }

      const updateCatalogItem = httpsCallable(functions, "updateCatalogItem")
      await updateCatalogItem(requestObj)
    },
  },
}
