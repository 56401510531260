<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      :closeDisabled="awaitingResponse"
      :awaitingResponse="awaitingResponse"
      title="Modifier Item"
      :error="mainErrorMessage"
      @close="closeForm()"
      @submit="submitHandle()"
    >
      <h3 class="my-4">Modifier Item Information</h3>
      <v-row v-if="modifierListName !== 'N/A'" class="mb-4">
        <v-col cols="12">Part of the "{{ modifierListName }}" modifier list.</v-col>
      </v-row>
      <v-row justify="start" class="mt-2">
        <v-col cols="12" md="7">
          <v-text-field v-model="name" label="Name" outlined hide-details="auto" />
        </v-col>
        <v-col cols="8" md="3">
          <v-text-field
            v-model="amount"
            label="Price"
            type="number"
            outlined
            prefix="$"
            suffix="CAD"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="4" md="2">
          <v-text-field
            v-model="ordinal"
            label="Ordinal"
            type="number"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <h3 class="my-5">Item Image</h3>
      <custom-image-upload
        name="modifierItems"
        :imageUrl="imageUrl"
        allowEdit
        @imageProcessed="imageUrl = $event"
      />
      <div v-if="inventoryEnabled">
        <h3 class="mt-5 mb-3">Recipe</h3>
        <ingredients v-model="ingredients" />
      </div>
    </form-dialog>
  </div>
</template>

<script>
import Ingredients from "../../../shared/forms/Ingredients.vue"
import FormDialog from "../../../shared/dialogs/FormDialog.vue"
import CustomImageUpload from "../../../shared/CustomImageUpload.vue"
import { mapState } from "vuex"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    Ingredients,
    CustomImageUpload,
  },
  data() {
    return {
      name: "",
      amount: "",
      ordinal: "",
      ingredients: [],
      awaitingResponse: false,
      imageUrl: "",
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["modifierLists", "itemToEdit", "settings"]),
    modifierListName() {
      if (this.itemToEdit?.modifierListId) {
        return this.modifierLists.find(
          item => item.id === this.itemToEdit.modifierListId
        ).name
      } else {
        return "N/A"
      }
    },
    editMode() {
      return this.itemToEdit != null
    },
    inventoryEnabled() {
      return this.settings?.inventoryEnabled
    },
  },
  watch: {
    "$route.params.form": {
      async handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.awaitingResponse = true

      let modifierItem = {
        id: this.itemToEdit?.id || "",
        name: this.name,
        priceMoney: {
          amount: Math.round(parseFloat(this.amount || 0) * 100),
          currency: "CAD",
        },
        ordinal: parseInt(this.ordinal),
        imageUrl: this.imageUrl || "",
        ingredients: [...this.ingredients],
      }

      if (!this.editMode) {
        modifierItem = {
          ...modifierItem,
          modifierListId: "",
          restaurantId: "",
          isDeleted: false,
          isSnoozed: false,
          snoozeUntil: 0,
        }
      }

      await this.$store
        .dispatch("updateModifierItem", modifierItem)
        .then(() => {
          this.$router.push({ params: { form: null, id: null } })
          this.clearFields()
          this.$emit("close")
        })
        .catch(error => {
          this.mainErrorMessage = error.message
        })
      this.awaitingResponse = false
    },
    prepEdit() {
      this.name = this.itemToEdit.name
      this.amount = (
        parseInt(this.itemToEdit.priceMoney.amount || 0) / 100
      )?.toFixed(2)
      this.ordinal = this.itemToEdit.ordinal || ""
      this.imageUrl = this.itemToEdit.imageUrl || ""
      this.ingredients =
        this.itemToEdit.ingredients?.length > 0 ? this.itemToEdit.ingredients : []
    },
    clearFields() {
      this.name = ""
      this.amount = ""
      this.ordinal = ""
      this.imageUrl = ""
      this.ingredients = []
      this.mainErrorMessage = ""
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("close")
    },
  },
}
</script>

<style></style>
