<template>
  <div>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="active" color="text" background-color="primary-darken4">
        <v-tab v-for="tab in tabs" :key="'tab-' + tab.name" :to="{ path: tab.name }">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="$route.params.tab" touchless>
        <v-tab-item
          v-for="tab in tabs"
          :key="'tab-' + tab.name"
          :value="tab.name"
          class="ma-4"
        >
          <component :is="tab.component" v-if="$route.params.tab === tab.name" />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import ModifierLists from "./Lists/index.vue"
import Modifiers from "./Items/index.vue"
import { mapActions } from "vuex"

export default {
  components: {
    ModifierLists,
    Modifiers,
  },
  data() {
    return {
      active: "items",
      tabs: [
        { name: "items", label: "Modifier Items", component: "modifiers" },
        { name: "lists", label: "Modifier Lists", component: "modifier-lists" },
      ],
    }
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
  },
}
</script>
