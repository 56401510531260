import { functions } from "../firebaseConfig"
import { httpsCallable } from "firebase/functions"
import getSquareAccessToken from "./getSquareAccessToken"

export default {
  mixins: [getSquareAccessToken],
  methods: {
    // dataObj includes the restaurantId and the id of the catalog item to be retrieved from square.
    async retrieveCatalogItemForUpdate(dataObj) {
      const requestObj = {
        accessToken: this.getAccessToken(dataObj.restaurantId),
        itemId: dataObj.itemId,
      }
      if (!this.getAccessToken(dataObj.restaurantId)) {
        return {}
      }
      const retrieveCatalogItemForUpdate = httpsCallable(
        functions,
        "retrieveCatalogItemForUpdate"
      )

      const response = await retrieveCatalogItemForUpdate(requestObj)
      return JSON.parse(response.data)
    },
  },
}
